jQuery(document).ready(function(){

    jQuery('.testimonial-slider').slick({
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    });

    jQuery('.hero-slider').slick({
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1
    });

});