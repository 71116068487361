var $ = jQuery;

/* Initiate the superfish submenu */
$( '.__desktop_menu' ).superfish( {
  animation: { height: 'show' }, // slide-down effect without fade-in
  delay: 300 // 0.3 second delay on mouseout
} );

/* Sticky Header */
if ( $(window).scrollTop() <= 300 ) {
  $( 'header' ).removeClass( '__sticky' );
} else {
  $( 'header' ).addClass( '__sticky' );
}
$( window ).scroll( function() {
  if ( $(window).scrollTop() <= 300 ) {
    $( 'header' ).removeClass( '__sticky' );
  } else {
    $( 'header' ).addClass( '__sticky' );
  }
} );

var mobyMenu = new Moby( {
  menu : $( '#mobile-menu' ), // The menu that will be cloned
  mobyTrigger : $( '#open-mobile-menu' ), // Button that will trigger the Moby menu to open
  menuClass : 'left-side',
  subMenuOpenIcon : '<i class="fas fa-caret-down"></i>',
	subMenuCloseIcon : '<i class="fas fa-caret-up"></i>',
  template : '<div class="moby-close"><span class="moby-close-icon"></span></div><div class="moby-wrap"><div class="moby-menu"></div></div>'
} );