( function( $ ) {
  /* Slider Banner */
  $( window ).load( function() {
    var slideWrapper = $( '.main-slider' ),
    iframes          = slideWrapper.find( '.embed-player' ),
    lazyImages       = slideWrapper.find( '.slide-image' ),
    lazyCounter      = 0;

    // POST commands to YouTube or Vimeo API
    function postMessageToPlayer( player, command ) {
      if ( player == null || command == null ) return;

      player.contentWindow.postMessage( JSON.stringify( command ), '*' );
    }

    // When the slide is changing
    function playPauseVideo( slick, control, slideWrapper ) {
      var currentSlide, slideType, startTime, player, video;

      currentSlide = slick.find( '.slick-current' );
      slideType    = currentSlide.attr( 'class' ).split(' ')[1];
      player       = currentSlide.find( 'iframe' ).get( 0 );
      startTime    = currentSlide.data( 'video-start' );

      if ( slideType === 'vimeo' ) {
        switch ( control ) {
          case 'play':
            if ( ( startTime != null && startTime > 0 ) && ! currentSlide.hasClass( 'started' ) ) {
              currentSlide.addClass('started');

              postMessageToPlayer( player, {
                'method' : 'setCurrentTime',
                'value'  : startTime
              } );
            }

            postMessageToPlayer( player, {
              'method' : 'play',
              'value'  : 1
            } );

            slideWrapper.slick( 'slickSetOption', 'autoplay', false, true );

            break;
          case 'pause':
            postMessageToPlayer( player, {
              'method' : 'pause',
              'value'  : 1
            } );

            slideWrapper.slick( 'slickSetOption', 'autoplay', true, true );

            break;
        }
      } else if ( slideType === 'youtube' ) {
        switch ( control ) {
          case 'play':
            postMessageToPlayer( player, {
              'event' : 'command',
              'func'  : 'mute'
            } );

            postMessageToPlayer( player, {
              'event' : 'command',
              'func'  : 'playVideo'
            } );

            slideWrapper.slick( 'slickSetOption', 'autoplay', false, true );

            break;
          case 'pause':
            postMessageToPlayer( player, {
              'event' : 'command',
              'func'  : 'pauseVideo'
            } );

            slideWrapper.slick( 'slickSetOption', 'autoplay', true, true );

            break;
        }
      } else if ( slideType === 'video' ) {
        video = currentSlide.children( 'video' ).get( 0 );

        if ( video != null ) {
          if ( control === 'play' ) {
            video.play();
          } else {
            video.pause();
          }
        }
      }
    }

    // Resize player
    function resizePlayer( iframes, temp_ratio ) {
      if ( ! iframes[0] ) return;

      var win = $( '.main-slider' ),
          width = win.width(),
          playerWidth,
          height = win.height(),
          playerHeight,
          ratio = temp_ratio || 16/9;

      iframes.each( function() {
        var current = $( this );
        if ( width / ratio < height ) {
          playerWidth = Math.ceil( height * ratio );

          current.width( playerWidth ).height( height ).css( {
            left : ( width - playerWidth ) / 2,
            top  : 0
          } );
        } else {
          playerHeight = Math.ceil( width / ratio );

          current.width( width ).height( playerHeight ).css( {
            left : 0,
            top  : ( height - playerHeight ) / 2
          } );
        }
      } );
    }

    // DOM Ready
    $( function() {
      // Initialize
      slideWrapper.on( 'init', function( slick ) {
        slick = $( slick.currentTarget );
        setTimeout( function(){
          if( $( window ).width() <= 639 ) {
            $( '.__play_video' ).show();
            playPauseVideo( slick, 'pause', slideWrapper );

            $( document ).on( 'click', '.__play_video', function() {
              $( this ).removeClass( '__play_video' ).addClass( '__pause_video' );
              playPauseVideo( slick, 'play', slideWrapper );
            } );

            $( document ).on( 'click', '.__pause_video', function() {
              $( this ).removeClass( '__pause_video' ).addClass( '__play_video' );
              playPauseVideo( slick, 'pause', slideWrapper );
            } );
          } else {
            playPauseVideo( slick, 'play', slideWrapper );
          }
        }, 1000 );

        resizePlayer( iframes, 16/9 );
      } );

      slideWrapper.on( 'beforeChange', function( event, slick ) {
        slick = $( slick.$slider );
        playPauseVideo( slick, 'pause', slideWrapper );
      } );

      slideWrapper.on( 'afterChange', function( event, slick ) {
        slick = $( slick.$slider );
        playPauseVideo( slick, 'play', slideWrapper );
      } );

      slideWrapper.on( 'lazyLoaded', function( event, slick, image, imageSource ) {
        lazyCounter++;
        if ( lazyCounter === lazyImages.length ) {
          lazyImages.addClass( 'show' );
        }
      } );

      //start the slider
      slideWrapper.slick( {
        autoplay      : true,
        autoplaySpeed : 4000,
        lazyLoad      : "progressive",
        speed         : 600,
        arrows        : false,
        dots          : true,
        cssEase       : "cubic-bezier(0.87, 0.03, 0.41, 0.9)"
      } );
    } );

    // Resize event
    $( window ).on( 'resize.slickVideoPlayer', function() {
      resizePlayer( iframes, 16/9 );
    } );

    if ( $( '.slick-dots li' ).length < 2 )
      $( '.main-slider' ).addClass( '__single_slide' )
  } );
} ) ( jQuery );